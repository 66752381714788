import CurrentUserStore from 'mewe/stores/current-user-store';
import ContactsApi from 'mewe/api/contacts-api';
import ProfileStore from 'mewe/stores/profile-store';

export default function ({ uuid = null, groupId, eventId, details = false } = {}) {
  CurrentUserStore.getState().deferred.promise.then(() => {
    if (!uuid) {
      uuid = CurrentUserStore.getState().get('id');
    }

    ContactsApi.getUserDetails({ userId: uuid, groupId, eventId, details })
      .then((data) => {
        ProfileStore.send('handleRawData', data, groupId, eventId);
      });
  });
}
