import { next } from '@ember/runloop';
import config from 'mewe/config/environment';

export const getWindowHeight = () => {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};

export const getWindowScrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
};

export const getElWidth = (el) => {
  return el ? parseFloat(getComputedStyle(el, null).width.replace('px', '')) : 0;
};

export const getElHeight = (el) => {
  return el ? parseFloat(getComputedStyle(el, null).height.replace('px', '')) : 0;
};

export const animateScrollTo = (element, to = 0, duration = 300, scrollToDone = null) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    currentTime += increment;

    const val = easeInOutQuad(currentTime, start, change, duration);

    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      if (scrollToDone) scrollToDone();
    }
  };

  animateScroll();
};

export const setSwipeListener = (el, dir, callback) => {
  const swipeTreshold = 30;

  let touchStartX = 0;
  let touchEndX = 0;

  el.addEventListener('touchstart', (event) => {
    touchStartX = event.changedTouches[0].screenX;
  });

  el.addEventListener('touchend', (event) => {
    touchEndX = event.changedTouches[0].screenX;

    if (Math.abs(touchEndX - touchStartX) < swipeTreshold) return;

    if (dir === 'left' && touchEndX > touchStartX) callback();
    if (dir === 'right' && touchEndX < touchStartX) callback();
  });
};

export class StickyController {
  constructor(opts = {}) {
    this.element = opts.element;
    this.lastElementHeight = null;
    this.lastViewHeight = null;
    this.customHeight = null;
    this.appTopPadding = 80;
    this.sidebarOffset = opts.sidebarOffset || this.appTopPadding;
    this.margin = opts.margin || 20;
    this.isSecondary = opts.isSecondary || false;
    this.extraHeight = 0;
    this.interval = null;
    this.position();
  }

  position() {
    // break the interval if element is not present anymore
    if (!this.element) {
      return clearInterval(this.interval);
    }

    // positioning interval is needed because sidebar/viewport heights can change
    if (config.environment !== 'test' && !this.interval) {
      // in tests this part causes timeouts of <AppMainFrame::SidebarRight> component
      this.interval = setInterval(this.position.bind(this), 1000);
    }

    // mobile view doesn't have sticky sidebars.
    // secondary sidebars (e.g. group settings sidebar) have different media-query break point
    if (window.innerWidth <= 620 || (this.isSecondary && window.innerWidth <= 900)) {
      this.element.style.top = '';
      return;
    }

    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const elementHeight = this.element.clientHeight + this.extraHeight;

    // don't do anything if sidebar height and window height didn't change
    if (this.lastElementHeight === elementHeight && this.lastViewHeight === viewHeight) {
      return;
    }

    this.lastElementHeight = elementHeight;
    this.lastViewHeight = viewHeight;

    if (elementHeight + this.sidebarOffset > viewHeight) {
      this.element.style.top = `${viewHeight - elementHeight - this.margin}px`;
    } else {
      this.element.style.top = '';
    }
  }

  // can be used when inside the sticky element there is some other element
  // positioned absolutely (e.g. dropdown menu) and it overflows the screen on bottom.
  // In such case this method can be used to pass some extra height to the sticky controller
  setExtraHeight(extraHeight, isInsert) {
    clearInterval(this.interval);
    this.interval = null;

    // in case the click closed one dropdown and opened another we need
    // to first compute the destroy and then the insert update in order
    // to set new extraHeight and do not reset it to 0
    if (isInsert) {
      next(this, () => {
        this.extraHeight = extraHeight;
        this.position();
      });
    } else {
      this.extraHeight = extraHeight;
      this.position();
    }
  }
}
