import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isMobile } from 'mewe/shared/utils';
import { next } from '@ember/runloop';

import { dateStringLongMonth, dateStringLongMonthDayYear } from 'mewe/utils/datetime-utils';
import dispatcher from 'mewe/dispatcher';
import { fetchUsersCounters } from 'mewe/fetchers/fetch-followers';
import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { ds } from 'mewe/stores/ds';
import { StickyController } from 'mewe/utils/elements-utils';
import Storage from 'mewe/shared/storage';
import PS from 'mewe/utils/pubsub';

export default class ProfileMain extends Component {
  @service chat;
  @service tasks;
  @service account;
  @service router;
  @service dynamicDialogs;

  @tracked showSettingsDropdown;
  @tracked showOptionsDropdown;
  @tracked dropdownMenuOpenedAvatar;
  @tracked dropdownMenuOpenedCover;
  @tracked avatarTask;
  @tracked coverTask;
  @tracked dialogTab;
  @tracked usersCounters = ds.usersCounters;

  get contact() {
    return this.args.contact;
  }

  get badges() {
    return this.args.contact.badges;
  }

  get isPrivate() {
    return this.contact.public === false;
  }

  isMobile = isMobile();

  avatarDialogTitles = {
    choosePhoto: __('Choose Profile Photo'),
    cropPhoto: __('Edit Profile Photo'),
  };

  coverDialogTitles = {
    choosePhoto: __('Choose Background Photo'),
    cropPhoto: __('Edit Background Photo'),
  };

  constructor() {
    super(...arguments);
    this.avatarTask = this.tasks.getTask('photo.avatar.contacts');
    this.coverTask = this.tasks.getTask('photo.cover.contacts');
    this.selectPhotoAvatarBind = this.selectPhotoAvatar.bind(this);
    this.selectPhotoCoverBind = this.selectPhotoCover.bind(this);
    this.userBlockedHandlerBind = this.userBlockedHandler.bind(this);
    PS.Sub('user.blocked', this.userBlockedHandlerBind);
  }

  @computed('counters.requestsReceived')
  get requestsReceivedOverOneHundred() {
    if (this.usersCounters?.requestsReceived >= 100) {
      return true;
    } else {
      return false;
    }
  }

  get memberSinceText() {
    if (this.contact.registeredAt) {
      return __('Member since {date}', {
        date: dateStringLongMonth(this.contact.registeredAt, this.account.activeUser.locale),
      });
    } else {
      return '';
    }
  }

  get memberSinceTitleText() {
    if (this.contact.registeredAt) {
      return __('Joined {date}', {
        date: dateStringLongMonthDayYear(this.contact.registeredAt, this.account.activeUser.locale),
      });
    } else {
      return '';
    }
  }


  @computed('isUserFollowedByMe', 'isMyProfile')
  get allowNsfwContent() {
    if (this.isUserFollowedByMe) {
      return true;
    }
    if (this.isMyProfile) {
      return true;
    }
    const isNsfwAllowed = Storage.get(Storage.keys.allowNsfwContentSetting);
    if (isNsfwAllowed) {
      return true;
    }
    return false;
  }

  @computed('account.activeUser.id', 'contact.id', 'args.uuid')
  get isMyProfile() {
    return this.contact && this.contact.id === this.account.activeUser.id;
  }

  @computed('args.entries.items.length')
  get hasEntries() {
    return this.args.entries?.items?.length > 0;
  }

  @computed('args.entries.items.length')
  get hasNewEntries() {
    return this.args.entries?.items?.filterBy('isNew', true).length > 0;
  }

  get canShowInfo() {
    if (this.args.flagNewProfiles) return false;
    return this.contact.isMe || this.contact.public || this.contact.following;
  }

  get canShowOptions() {
    return !this.contact.isMe;
  }

  get canShowSettings() {
    return this.contact.isMe;
  }

  get canChat() {
    return this.contact.canChat && !this.contact.isMe;
  }

  get isFollowingMe() {
    return this.contact.follower;
  }

  get isUserFollowedByMe() {
    return this.contact.following;
  }

  get canSeeFollowers() {
    return (this.contact.following && !this.contact.hideFollowers) || this.contact.public || this.isMyProfile;
  }

  userBlockedHandler(data) {
    if (data.userId === this.contact.id) {
      this.router.transitionTo('app.myworld');
    }
  }

  openJournalsDialog() {
    const params = {
      tellerId: this.contact.id,
      tellerName: this.contact.name,
      tellerType: 'User', // for now only User type exist
      avatarHref: this.contact._links.avatar.href,
      context: 'profile_feed',
      isPublicProfile: this.contact.public,
    };

    this.dynamicDialogs.openDialog('journals-dialog', params);
  }

  @action
  removeFollower() {
    dispatcher.dispatch('contact', 'removeFollower', this.contact);
    this.showOptionsDropdown = false;
  }

  @action
  shareProfile() {
    MiscellaneousUtils.copyToClipboard(`${EnvironmentUtils.getHost()}/${this.contact.publicLinkId}`);
    this.showOptionsDropdown = false;
  }

  @action
  inviteUserToGroup() {
    dispatcher.dispatch('contact', 'addUserToGroup', this.contact);
  }

  @action
  blockUser() {
    dispatcher.dispatch('contact', 'blockUser', this.contact, () => this.router.transitionTo('app.myworld'));
  }

  @action
  openFollowingDialog(tab) {
    if (this.args.isPublicProfilePage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.canSeeFollowers) {
      this.dialogTab = tab;
      this.openFollowerDialog();
    }
  }

  @action
  displayopenFollowerDialogPopup() {
    if (this.args.isPublicProfilePage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    this.openFollowerDialog();
  }

  @action
  showNewToMeWePopup() {
    this.args.isPublicProfilePage && showNewToMeWePopup(this.dynamicDialogs);
  }

  @action
  reportUser() {
    dispatcher.dispatch('contact', 'reportUser', this.contact);
  }

  @action
  openAvatarPhoto() {
    if (this.args.isPublicProfilePage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    if (this.hasEntries) {
      this.openJournalsDialog();
    } else {
      let photoUrl = this.contact._links.avatar.href;

      this.dynamicDialogs.openDialog('media-dialog', {
        photoUrl: photoUrl,
        mediaType: 'photoUrl',
        allowMultipleInstances: true,
      });
    }
  }

  @action
  openCoverPhoto() {
    if (this.args.isPublicProfilePage) {
      return showNewToMeWePopup(this.dynamicDialogs);
    }

    let photoUrl = this.contact._links.cover.href;

    this.dynamicDialogs.openDialog('media-dialog', {
      photoUrl: photoUrl,
      mediaType: 'photoUrl',
      allowMultipleInstances: true,
    });
  }

  selectPhotoAvatar(blob, params) {
    dispatcher.dispatch('contact', 'setAvatar', blob, params, this.avatarTask);
  }

  selectPhotoCover(blob, params) {
    dispatcher.dispatch('contact', 'setPublicCover', blob, params, this.coverTask);
  }

  @action
  opendEditDropdownAvatar() {
    this.dropdownMenuOpenedAvatar = true;
  }

  @action
  closeEditDropdownAvatar() {
    this.dropdownMenuOpenedAvatar = false;
  }

  @action
  opendEditDropdownCover() {
    this.dropdownMenuOpenedCover = true;
  }

  @action
  closeEditDropdownCover() {
    this.dropdownMenuOpenedCover = false;
  }

  @action
  openChat() {
    this.chat.openThreadByParticipants([this.contact], { doNotClose: true });
  }

  @action
  showInfo() {
    this.showAboutDialog();
  }

  @action
  showOptions(value) {
    this.showOptionsDropdown = typeof value === 'boolean' ? !value : !this.showOptionsDropdown;
  }

  @action
  showSettings() {
    if (this.isMyProfile) {
      this.router.transitionTo('app.settings.privacy');
      return;
    }
  }

  @action
  showAboutDialog() {
    this.dynamicDialogs.openDialog('about-dialog', {
      uuid: this.args.uuid,
      memberSinceText: this.memberSinceText,
    });
  }

  @action
  openFollowerDialog() {
    this.dynamicDialogs.openDialog('followers-dialog', {
      uuid: this.args.uuid,
      currentTab: this.dialogTab,
      title: this.contact.name,
      isPublicProfile: this.contact.public,
    });
  }

  @action
  onInsert(element) {
    this.stickyController = new StickyController({ element: element });

    if (!this.args.isPublicProfilePage) {
      fetchUsersCounters();
    }
  }

  @action
  onDestroy() {
    PS.Unsub('user.blocked', this.userBlockedHandlerBind);
  }

  @action
  stickyHeightUpdate(isInsert, element) {
    this.stickyController.setExtraHeight(element.clientHeight, isInsert);
  }
}
