import layout from './template.hbs';
import './styles.scss';

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import PopupOpener from 'mewe/pods/components/basic-ui/mw-popup/base/component';
import { getElWidth, getElHeight, getOffset, getWindowScrollTop, getWindowHeight } from 'mewe/utils/elements-utils';

export default class MwEmojiListPopup extends PopupOpener {
  layout = layout;

  delayUntilOpen = 600;

  popupType = 'emoji-list-popup';

  closeOnHoverOutside = true;

  closeOnScroll = true;

  insideAnotherScrollable = false;

  @tracked emojiSenders = A();
  @tracked maxHeight;

  constructor() {
    super(...arguments);

    this.groupId = this.args.model.isGroupChat ? this.args.model.id : this.args.model.groupId; // groupId/eventId needed for color class in popup mixin
    this.eventId = this.args.model.isEventChat ? this.args.model.id : this.args.model.eventId;
  }

  getPlacement() {
    const parent = this.args.parent,
      parentWidth = getElWidth(parent);

    if (!parent || parentWidth === 0) return; // checking width==0, element could be destroyed during popup opening timeout

    const parentOffset = getOffset(parent),
      parentHeight = getElHeight(parent),
      popupHeight = getElHeight(this.element),
      popupWidth = getElWidth(this.element);

    const spaceToBottom = getWindowHeight() - (parentOffset.top - getWindowScrollTop() + parentHeight);
    const spaceToTop = window.innerHeight - spaceToBottom;
    const minListScrollHeight = 80;
    const maxPopupHeight = 380;

    const placement = {};

    // emojis are usually below content, better not to cover content by popup and bottom position has priority if possible
    if (spaceToBottom > maxPopupHeight || spaceToBottom > spaceToTop) {
      placement.top = parentOffset.top + parentHeight;

      // experimental values, to make it px-perfect
      if (this.args.isMessage) placement.top -= 3;
      else placement.top -= 3;

      // limit max height of emojis list to fit popup to page without scrolling
      // but max height can't be lower that some reasonable min-height like 85px
      if (spaceToBottom < maxPopupHeight) this.maxHeight = Math.max(spaceToBottom - 85, minListScrollHeight);
    } else {
      placement.top = parentOffset.top - popupHeight - parentHeight;

      // experimental values, to make it px-perfect
      if (this.args.isMessage) placement.top += 4;
      else placement.top += 22;

      if (spaceToTop < maxPopupHeight) this.maxHeight = Math.max(spaceToTop - 85, minListScrollHeight);

      this.popupOnTop = true;
    }

    placement.left = parentOffset.left - popupWidth + parentWidth + 13; // 13 - experimental value, it just looks px-perfect

    if (placement.left < 0) {
      // set marginRight before overriding placement.left
      placement.marginRight = -placement.left + 5; // used for arrow position as inherited margin from parent element
      placement.left = 5;
    }

    return placement;
  }

  @action
  updatePlacement(emojiSenders) {
    if (emojiSenders.length === 0) {
      this.close();
    } else {
      this.position();
    }
  }
}
