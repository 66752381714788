import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import FollowingApi from 'mewe/api/following-api';
import ProfileStore from 'mewe/stores/profile-store';

export default class AppProfile extends Component {
  @service account;

  get isFollowingRequestReceived() {
    return isPresent(this.args.model.contact.followRequestReceived);
  }

  get isMyProfile() {
    return this.args.model.isMyProfile;
  }

  get flagNewProfiles() {
    return this.args.model.flagNewProfiles;
  }

  @dropTask
  *acceptFollowRequest() {
    let data = yield FollowingApi.acceptRequestSent(this.args.model.contact.followRequestReceived);
    if (data?.limitReached) {
      this.account.followLimitPopup();
      return
    }

    data = data.follow;
    data.followRequestReceived = '';
    data = { ...data, ...data.user };
    delete data.user;
    ProfileStore.send('updateData', data);
  }

  @action
  async declineFollowRequest() {
    await FollowingApi.cancelRequestSent(this.args.model.contact.followRequestReceived);
    ProfileStore.send('updateData', { id: this.args.model.contact.id, followRequestReceived: '' });
  }
}
