import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PS from 'mewe/utils/pubsub';
import Component from '@glimmer/component';

import './styles.scss';

export default class CropPhoto extends Component {
  @service dynamicDialogs;

  @tracked loading = true;

  @tracked cropData;

  @tracked blob;

  @tracked closeBind;

  get ratio() {
    if (this.args.theme == 'cover-profile') {
      return 1.11;
    } else if (this.args.theme == 'cover') {
      return 0.338624339;
    } else {
      return 1;
    }
  }

  @action
  didInsert() {
    this.closeBind = this.close.bind(this);
    PS.Sub('close.crop.dialog', this.closeBind);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    PS.Unsub('close.crop.dialog', this.closeBind);
  }

  @action
  closeDialog() {}

  @action
  setLoading(isLoading) {
    this.loading = isLoading;
  }

  @action
  onCrop(data, blob) {
    this.cropData = {
      croppX: data.left,
      croppY: data.top,
      croppW: data.width,
      croppH: data.height,
    };

    this.blob = blob;
  }

  @action
  back() {
    this.args.onClose?.();
  }

  @action
  close(dontClear) {
    if (!dontClear) {
      window.onbeforeunload = null;
    }

    this.args.onClose?.(true);
  }

  @action
  save() {
    let params = Object.assign({}, this.cropData, { id: this.id });

    this.args?.updatePhoto?.(this.blob, params);
    this.close(true);
  }
}
