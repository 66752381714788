import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { translationFields } from 'mewe/constants';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import ProfileApi from 'mewe/api/profile-api';
import ProfileStore from 'mewe/stores/profile-store';
import fetchProfile from 'mewe/fetchers/fetch-profile';
import { dateStringLongMonth } from 'mewe/utils/datetime-utils';

export default class AppProfileAbout extends Component {
  @service features;
  @service account;
  @service dynamicDialogs;

  @tracked contact;

  @tracked editMode = false;
  @tracked isTranslated = false;
  @tracked contentTranslated = false;
  @tracked errorMessage = '';
  @tracked supportedTranslation = translationFields.find((field) => field.type === 'profile');

  constructor() {
    super(...arguments);

    this.userId = this.args.model.appProfile.uuid;

    fetchProfile({ uuid: this.userId, details: true });
    this.contact = ProfileStore.getState({ uuid: this.userId });
  }

  get profile() {
    return this.contact?.profile;
  }

  get canShowEditView() {
    return this.args.model.appProfile?.isMyProfile && !this.editMode;
  }

  get isDescriptionError() {
    return this.errorMessage.includes('Text');
  }

  get isCityError() {
    return this.errorMessage.includes('City');
  }

  get isJobError() {
    return this.errorMessage.includes('Job');
  }

  get isCompanyError() {
    return this.errorMessage.includes('Company');
  }

  get isHighSchoolError() {
    return this.errorMessage.includes('High school');
  }

  get isCollegeError() {
    return this.errorMessage.includes('College');
  }

  get isRelationshipStatusError() {
    return this.errorMessage.includes('Relationship Status');
  }

  get isInterestsError() {
    return this.errorMessage.includes('Interests');
  }

  get displayTranslationFeature() {
    const isLangDifferent = this.account.currentTranslationLanguage !== this.profile?.language;
    const notMyProfile = this.account.activeUser.id !== this.userId;
    const canTranslate =
      this.profile?.language &&
      isLangDifferent &&
      notMyProfile &&
      this.features.get('translationsProfile') &&
      this.hasMinimumRequiredChars;

    return this.profile?.isTranslated || canTranslate;
  }

  get hasMinimumRequiredChars() {
    let fieldTranslationsLength = 0;

    this.supportedTranslation.translations.forEach((field) => {
      if (this.profile?.[field]) {
        const profileField = this.profile?.[field];

        fieldTranslationsLength += profileField?.length;
      }
    });

    return fieldTranslationsLength > this.account.minimumNumberOfCharacters;
  }

  get memberSinceDate() {
    if (this.contact.registeredAt) {
      return dateStringLongMonth(this.contact.registeredAt, this.account.activeUser.locale);
    }
    return '';
  }

  @action
  focusOnFirstEditor() {
    const element = document.querySelector('#edit-description .ql-editor');
    element.focus();
    window.getSelection().selectAllChildren(element);
    window.getSelection().collapseToEnd();
  }

  @action
  openEditMode() {
    this.editMode = true;
  }

  @action
  cancelEditMode() {
    this.editMode = false;
  }

  @dropTask
  *saveProfile() {
    try {
      yield ProfileApi.setMyPublicProfile({
        text: this.profile.textServer,
        currentCity: this.profile.currentCityServer,
        job: this.profile.jobServer,
        company: this.profile.companyServer,
        college: this.profile.collegeServer,
        highSchool: this.profile.highSchoolServer,
        relationshipStatus: this.profile.relationshipStatusServer,
        interests: this.profile.interestsServer,
      });
      ProfileStore.send('updateData', this.contact);
      this.errorMessage = '';
      this.editMode = false;
    } catch (error) {
      this.errorMessage = error.data.message || '';
    }
  }

  @action
  handleTranslations(translation) {
    if (translation && !translation.errorCode) {
      if (!this.contentTranslated) {
        this.contentTranslated = true;
      }
      this.isTranslated = translation.isTranslated;
      const translationFields = this.isTranslated ? this.supportedTranslation.translationServerFields :  this.supportedTranslation.translations;
      const newProfile = { ...this.profile };
      for (const field in translation.data) {
        const isSupported = translationFields.includes(field);
        const checkedField = this.isTranslated ? field + 'Display' : field;
        if (isSupported && this.profile[checkedField]) {
          newProfile[checkedField] = translation.data[field];
        }
      }
      newProfile.language = this.profile.language;
      this.contact.profile = newProfile;
    }
  }

  @action
  updateContent(propertyName, content) {
    this.profile[propertyName] = content;
  }
}
