import ApiUtil from './api';

class Api extends ApiUtil {
  setMyGenericProfilePicture(params) {
    return this.put('/profile', {
      data: JSON.stringify(params),
    });
  }

  setGroupProfilePictureAndDesc(groupId, params) {
    return this.put('/profile/group/' + groupId, {
      data: JSON.stringify(params),
    });
  }

  setMyPublicProfile(params) {
    return this.put('/profile/public', {
      data: JSON.stringify(params),
    });
  }

  setCoverPhoto(params) {
    return this.put('/profile/cover', {
      data: JSON.stringify(params),
    });
  }
}

export default new Api();
