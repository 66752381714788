import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class MwNavSimple extends Component {
  @tracked showLeftArrow = false;
  @tracked showRightArrow = false;

  @action
  onDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  @action
  setupScrollObserver(element) {
    this.navElement = element;

    // Check for overflow and arrow visibility on mount
    this.checkOverflow();

    // Create resize observer to check overflow on resize
    this.resizeObserver = new ResizeObserver(() => {
      this.checkOverflow();
    });
    this.resizeObserver.observe(element);

    // Listen for scroll events
    element.addEventListener('scroll', () => this.updateArrowVisibility());
  }

  @action
  checkOverflow() {
    if (this.navElement) {
      const hasOverflow = this.navElement.scrollWidth > this.navElement.clientWidth;
      this.showRightArrow = hasOverflow;
      this.updateArrowVisibility();

      // quick hack for use case when tabs are dynamically loaded after NAV is already mounted
      // ideally we should have a more elegant way to handle this
      if (this.args.scheduleUpdater && !this.updateInterval) {
        this.updateInterval = setInterval(() => {
          this.checkOverflow();
        }, 1000);
      }
    }
  }

  @action
  updateArrowVisibility() {
    const isRtl = document.dir === 'rtl';

    if (this.navElement) {
      const scrollLeft = isRtl ? -this.navElement.scrollLeft : this.navElement.scrollLeft;
      const remainingScroll = this.navElement.scrollWidth - (Math.abs(this.navElement.scrollLeft) + this.navElement.clientWidth);

      // Show left/start arrow if scrolled from start
      this.showLeftArrow = scrollLeft > 0;

      // Show right/end arrow if there's more content to scroll to
      this.showRightArrow = remainingScroll > 1; // Using 1px threshold to account for rounding

      if (isRtl) {
        // Swap the arrows for RTL
        [this.showLeftArrow, this.showRightArrow] = [this.showRightArrow, this.showLeftArrow];
      }
    }
  }

  @action
  scrollLeft() {
    if (this.navElement) {
      const scrollAmount = this.navElement.clientWidth / 2;
      this.navElement.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    }
  }

  @action
  scrollRight() {
    if (this.navElement) {
      const scrollAmount = this.navElement.clientWidth / 2;
      this.navElement.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  }

  willDestroy() {
    super.willDestroy();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
