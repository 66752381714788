import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { StickyController } from 'mewe/utils/elements-utils';

export default class SidebarLeft extends Component {
  @tracked mobileSidebarVisible = false;

  @action
  onInsert(element) {
    this.stickyController = new StickyController({
      element: element,
      isSecondary: this.args.isSecondary,
      sidebarOffset: this.args.sidebarOffset,
    });
  }

  @action
  updateMobileSidebarVisible(value) {
    this.mobileSidebarVisible = value;
  }
}
