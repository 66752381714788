import Component from '@glimmer/component';
import { action } from '@ember/object';

import { StickyController } from 'mewe/utils/elements-utils';

export default class SidebarRight extends Component {
  @action
  onInsert(element) {
    this.stickyController = new StickyController({
      element: element,
      isSecondary: this.args.isSecondary,
      sidebarOffset: this.args.sidebarOffset,
    });
  }
}
