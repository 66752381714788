import { computed } from '@ember/object';
import { A } from '@ember/array';
import Model from 'mewe/utils/store-utils/model/model.js';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { dateStringCalendar } from 'mewe/utils/datetime-utils';
import Profile from './profile-model';
import GroupDescription from './group-description-model';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';
import isUndefined from 'mewe/utils/isUndefined';
import { isString, cond } from 'lodash';

/*
"_links": {
  "avatar": {
    "href": "/api/v2/photo/profile/{imageSize}/53a699d6e4b0dcca00011473?group=&f=F357389691548S4ZJ53",
    "templated": true
  },
  "cover": {
    "href": "/img/predefined/cover-picture.jpg"
  },
  "self": {
    "href": "/api/v2/mycontacts/user/53a699d6e4b0dcca00011473"
  }
}

*/

var model = Model.extend(modelProfileId, {
  init() {
    this.set('currentUser', CurrentUserStore.getState());
    this._super();
  },

  id: attr('string'),
  publicLinkId: attr('string'),
  name: attr('string'),
  firstName: attr('string'),
  lastName: attr('string'),
  fingerprint: attr('string'),
  canChat: attr('boolean', {
    defaultValue: false,
  }),
  public: attr('boolean'),
  favorite: attr('boolean'),
  userAllowsToBeInvited: attr('boolean', {
    defaultValue: false,
  }),
  canBeInvited: attr('boolean', {
    defaultValue: true,
  }),
  nsfw: attr('boolean', {
    defaultValue: false,
  }),
  isContact: attr('boolean'),
  _links: attr('object'),
  storyFilter: attr('object'),
  contactInvitationReminder: attr('object'),
  groupRole: attr('string'),
  groupDescription: belongsTo(GroupDescription, {
    defaultValue: () => GroupDescription.create(),
  }),
  emails: attr('array'),
  phoneNumbers: attr('array'),
  verified: attr('boolean'),
  donor: attr('boolean'),
  blocked: attr('boolean', {
    defaultValue: false,
  }),
  // following - I follow user
  following: attr('boolean', {
    defaultValue: false,
  }),
  // follower - user follows me
  follower: attr('boolean', {
    defaultValue: false,
  }),
  hideFollowers: attr('boolean', {
    defaultValue: false,
  }),
  muted: attr('boolean'),
  followRequestSent: attr('string', {
    defaultValue: function () {
      return null;
    },
  }),
  followRequestReceived: attr('string', {
    defaultValue: function () {
      return null;
    },
  }),

  counters: attr('object'),
  limitReached: attr('boolean'),

  isSuggested: attr('boolean', {
    defaultValue: false,
  }),

  profile: belongsTo(Profile),
  badges: attr('object'),
  timezones: attr('object'),
  registeredAt: attr('number'), // deprecated in favour of 'joined'
  joined: attr('number'),

  deactivated: attr('boolean', {
    defaultValue: false,
  }),
  invitedBy: attr('object'),
  invitedOn: attr('date'),
  nsfwAvatar: computed('_links.avatar.nsfw', function () {
    return this._links?.avatar?.nsfw;
  }),
  timeDisplay: computed('timezones.zone', function () {
    const locale = this.get('currentUser.jsLocale'),
      now = new Date();

    if (this.get('timezones.zone')) {
      return now.toLocaleTimeString(locale, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.get('timezones.zone'),
      }); // "8:30 PM";
    }

    return now.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' }); // "8:30 PM";
  }),

  entityType: attr('string'),

  cover: computed('_links.cover.href', function () {
    /* link from
      GET /api/v2/mycontacts/user/606d9256b7b18c1956170dcf
      returns cover with prefix. After fixing it should be:
      // [isString, (link) => utils.getImgHost(true) + link],
    */
    return cond([
      [isString, (link) => link],
      [isUndefined, () => ''],
    ])(this.get('_links.cover.href'));
  }),

  connected: computed('isMe', 'canBeInvited', 'loaded', 'nonContactCannotInvite', 'isContact', function () {
    return !this.isMe && !this.nonContactCannotInvite && this.isContact;
  }),

  invited: computed('{isMe,followRequestSent}', function () {
    return !this.isMe && this.followRequestSent;
  }),

  loaded: computed('id', function () {
    return this.id;
  }),

  nonContactCannotInvite: computed('userAllowsToBeInvited', 'isMe', function () {
    return !this.userAllowsToBeInvited && !this.isMe;
  }),

  role: computed('groupRole', function () {
    return { name: this.groupRole };
  }),

  isMe: computed('id', 'currentUser.id', function () {
    return this.id === this.get('currentUser.id');
  }),

  canSeeAbout: computed('isMe', 'public', 'following', function () {
    return this.isMe || this.public || this.following;
  }),

  reminder: attr('object', {
    defaultValue: false,
  }),

  canRemind: computed('contactInvitationReminder', function () {
    if (!this.contactInvitationReminder) return false;

    var a = this.contactInvitationReminder.remindDelay;
    var b = this.contactInvitationReminder.remindSubscr;

    return !a && b;
  }),

  invitationSent: attr('boolean', {
    defaultValue: false,
  }),

  emailOrPhone: computed('emails', 'phoneNumbers', function () {
    return this.get('emails.length') ? this.emails[0] : this.get('phoneNumbers.length') ? this.phoneNumbers[0] : null;
  }),

  inviteDateFormatted: computed('invitedOn', function () {
    return dateStringCalendar(this.invitedOn, this.get('currentUser.jsLocale'));
  }),

  // moved from app-profile-posts page here because it's used in two separate places that can't share state
  // and it's better to have it in one place to have this value consistent in both places
  isFeedPreferencesVisible: computed('connected', 'isMe', 'followRequestReceived', 'favorite', function () {
    return this.connected && !this.isMe && !this.followRequestReceived && !this.favorite;
  }),
});

model.reopenClass({
  resourceName: 'contact',
});

export default model;
